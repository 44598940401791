<template>
  <div
    v-click-outside="closeDropdown"
    class="main-section-customdrop cu-drop-parent btn-dropdown"
  >
    <button
      class="selectmember"
      type="button"
      @click="isVisible = !isVisible"
    >
      <b-badge
        v-if="localValue && localValue.length"
        href="#"
        pill
        class="badge-pill-filter"
      >
        {{ multiple ? localValue.length : 1 }}
      </b-badge>
      <feather-icon
        v-if="icon"
        :icon="icon"
        size="16"
      />
      <span v-if="label">{{ label }}</span>
      <feather-icon
        v-if="label && localValue && localValue.length && !hideClose"
        icon="XIcon"
        size="14"
        @click="clearValues()"
      />
      <feather-icon
        v-if="label"
        icon="ChevronDownIcon"
      />

      <b-input-group
        v-if="inputselect"
        class="input-group-merge input-merge"
      >
        <b-form-input
          class="inputselect"
          placeholder="Select member"
          :inputselect="inputselect"
        />
        <b-input-group-append is-text>
          <feather-icon
            class="cursor-pointer"
            icon="ChevronDownIcon"
          />
        </b-input-group-append>
      </b-input-group>
    </button>
    <div
      v-show="isVisible"
      class="radio-dropdown"
    >
      <div class="main-div form-custom-dropdown">
        <div
          v-if="searchBox"
          class="main-sectiondropdown"
        >
          <input
            v-model="search"
            type="text"
            name="search"
            placeholder="Search"
            autocomplete="off"
          >
        </div>
        <hr
          v-if="multiple"
          class="horizontal-line"
        >
        <div>
          <div class="main-userlist-dropdown">
            <div>

              <ul
                v-if="optionData.length"
                class="user-dropdown"
              >
                <li v-if="multiple">
                  <b-form-checkbox
                    v-model="selectAllChecked"
                    @change="toggleSelectAll"
                  >
                    <span
                      :class="'mouse-cursor'
                      "
                      style="text-transform: none;"
                    >Select All &nbsp;</span>
                  </b-form-checkbox>
                </li>

                <li
                  v-for="(option, oindex) in optionData"
                  :key="oindex"
                  :class="(option.access_rights == 'project_owner' && disabled) ||
                    createdBy == option.value
                    ? 'remove-background'
                    : null
                  "
                >
                  <b-form-checkbox
                    v-if="multiple"
                    v-model="localValue"
                    :value="option.id"
                  >
                    <span
                      :class="(option.access_rights == 'project_owner' && disabled) ||
                        createdBy == option.value
                        ? 'mouse-cursor'
                        : 'cursor-pointer'
                      "
                      style="text-transform: none;"
                    >{{ option.name }}<b
                      v-if="option && option.type && option.type == 'productive'
                      "
                      style="color: #2178fb"
                    >$</b></span>
                  </b-form-checkbox>
                  <b-form-radio
                    v-else
                    v-model="localValue"
                    :value="option.id"
                    class="custom-control-secondary"
                  >
                    <span
                      class="cursor-pointer custom-radio-label"
                      style="text-transform: none;"
                    >{{
                      option.name
                    }}</span>
                  </b-form-radio>
                </li>
              </ul>
              <ul
                v-else
                class="user-dropdown"
              >
                <li class="text-center nodata-found">
                  No matching options
                </li>
              </ul>
            </div>
          </div>
          <div class="fancy-btn">
            <button>
              <slot />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from '@/main'

export default {
  name: 'CustomDropdown',
  components: {},
  directives: {
    'click-outside': {
      bind(el, binding, vNode) {
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) {
            warn += `Found in component '${compName}'`
          }
          console.warn(warn)
        }
        const { bubble } = binding.modifiers
        const handler = e => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
      },
      unbind(el, binding) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      },
    },
  },
  model: {
    event: 'change',
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    inputselect: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Array, String, Number],
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchBox: {
      type: Boolean,
      default: true,
    },
    createdBy: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    isSelectAll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: false,
      search: null,
      selectAllChecked: false,
    }
  },
  computed: {
    optionData() {
      if (this.search) {
        const that = this
        return this.options.filter(element => element.name.toLowerCase().match(that.search.toLowerCase()))
      }
      return this.options
    },
    valueString() {
      return this.multiSelect ? this.value.join(', ') : this.value
    },
    localValue: {
      get() {
        return this.value
      },
      set(data) {
        this.$emit('change', data)
      },
    },
    multiSelect() {
      return Array.isArray(this.value)
    },
  },
  watch: {
    isVisible(nv) {
      if (!nv) {
        this.search = null
      }
    },
    search(nv) {
      this.$emit('search', nv)
    },
    localValue() {
      this.updateSelectAllState()
    },
  },
  mounted() {
    eventBus.$on('closeCustomDropDown', () => {
      this.isVisible = false
    })
    this.updateSelectAllState()
    // Select all options by default
    setTimeout(() => {
      if (this.multiple && this.options) {
        if (this.isSelectAll) {
          this.localValue = this.options.map(option => option.id)
          this.selectAllChecked = this.isSelectAll
        }
      }
    }, 500)
  },
  methods: {
    clearValue() {
      this.localValue = null
    },
    closeDropdown() {
      this.isVisible = false
    },
    clearValues() {
      this.isVisible = false
      this.localValue = this.multiSelect ? [] : null
    },
    toggleSelectAll() {
      if (this.selectAllChecked) {
        this.localValue = this.options.map(option => option.id)
      } else {
        this.localValue = []
      }
    },
    updateSelectAllState() {
      this.selectAllChecked = this.localValue.length === this.options.length
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables.scss";

.selectmember {
  cursor: pointer;
  margin-left: 2px;
  background-color: transparent;
  border-color: transparent;
  color: $logo-color;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: map-get($mediaSizes , "2xl")) {
    margin-left: 6px;
  }

  @media (max-width: map-get($mediaSizes , "lg")) {
    margin-left: 0;
    margin: 7px 0;
  }

  span {
    font-size: 1.75 * $rem;
    font-weight: $fw500;
    text-transform: capitalize;
    line-height: 23px;
  }

  .feather-edit {
    margin-right: 8px;
  }

  .feather-trello {
    margin-right: 8px;
  }

  .feather-chevron-down {
    margin-left: 8px;
  }

  .feather-x {
    margin-left: 8px;
  }

  .badge-pill-filter {
    position: absolute;
    top: -12px;
    right: 13px;
    display: flex;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: rgba(255, 159, 67, 0.7);

    &:hover {
      background-color: rgba(255, 159, 67, 0.7);
    }
  }
}

.radio-dropdown {
  position: absolute;
  z-index: 9999;
  left: 20px;
  width: 230px;
}

.main-div {
  background-color: #ffff;
  border-radius: 6px;
  box-shadow: $box-shadow;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;

  .main-userlist-dropdown {
    ul {
      padding-left: 0;
      padding-bottom: 8px;

      li {
        padding: 5px 12px;

        &:hover {
          background-color: rgba(215, 231, 254, 0.2);
        }
      }

      label {
        color: #6e6b7b !important;
        font-size: 1.75 * $rem;
        width: 100%;

        span {
          &::before {
            opacity: 0.36;
          }
        }
      }
    }

   
  }

  input {
    border: 1px solid #d8d6de;
    padding: 8px 15px 8px 15px;
    border-radius: 5px;
    width: 100%;
    z-index: 1;
    color: $calendar-font;
    cursor: pointer;

    &::placeholder {
      color: #99989d;
      font-size: 1.75 * $rem;
      font-weight: $fw600;
      line-height: 23px;
      opacity: 1;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .horizontal-line {
    margin: 9px 0px;
  }

  .custom-control-secondary .custom-control-input:checked~.custom-control-label::before {
    opacity: 1;
    cursor: pointer;
    background-color: $primary-color !important;
    width: 10px;
    height: 10px;
    border: 1px solid $primary-color;
    left: 4px;
    top: 7px;
    outline: 4px solid rgba(33, 120, 251, 0.2);
    box-shadow: none !important;
  }

  .custom-control-label::before {
    background-color: transparent;
  }

  .custom-control-label::after {
    border-radius: 50%;
    background-color: transparent;
    border: 1.5px solid #d8d6de;
  }

  .custom-control-input:checked~.custom-control-label::after {
    border: 1px solid $primary-color;
  }

  .main-sectiondropdown {
    padding: 12px 9px 0px 13px;

    // margin-top: 8px;
    ul {
      padding-left: 0;

      li {
        margin-top: 16px;

        label {
          color: $logo-color !important;
          font-size: 1.75 * $rem;
          cursor: pointer;
        }
      }
    }
  }
}

.custom-control-secondary .custom-control-input:active~.custom-control-label::before {
  opacity: 0.36;
}

.custom-control-label::before {
  background-color: #efefef;
  border: transparent;
  box-shadow: none !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: $dark-gray;
  background-color: $dark-gray;
  box-shadow: none !important;
}

.custom-control-input:checked {
  &:focus-visible {
    color: #e0e0e0;
  }
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  // background-image: url("../../assets/images/Image/Path.svg");
  border-radius: 3px;
}

.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: $primary-color;
}

.inputselect {
  border: 1px solid $input-outline;
  padding: 8px 15px 8px 15px;
  border-radius: 5px;
  color: $font-color;

  &:focus {
    outline-color: $input-outline;
  }
}

.input-merge {
  max-width: 230px;
}

.main-section-customdrop {
  position: relative;
}

.custom-control-label:has(.custom-radio-label) {
  line-height: 25px;
}

.mouse-cursor input {
  cursor: default;
}

.remove-background {
  background: white !important;
}

.main-div .main-userlist-dropdown ul label {
  color: #6e6b7b !important;
  font-size: 14px;
  width: 102%;
}

//max-height: 340px;
.form-custom-dropdown {
  overflow: hidden;
}
</style>
